import React, { useEffect, useState } from "react";
import "./blog.css";

import { BlogMenuDesktop } from "../../components/blog_components/BlogMenuDesktop";
import { BlogTextBox } from "../../components/blog_components/BlogTextBox";

import { atom, useRecoilState } from "recoil";
import { VerticalMenuList } from "../../components/VerticalMenuList";

import { useNavigate } from "react-router-dom";

export const currentBlogText = atom({
  key: "blogText",
  default: "empty",
});

export function BlogPage(props: any) {
  const [blogTextMain, setBlogText] = useRecoilState(currentBlogText);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileMenuToggled, setMobileMenu] = useState(false);
  const navigate = useNavigate();

  let artworkTab = () => {
    props.setTab("artworksTab");
  };
  let projectsTab = () => {
    props.setTab("projectsTab");
  };
  let blogTab = () => {
    props.setTab("blogTab");
  };

  useEffect(() => {
    if (isMobileMenuToggled) {
      setMobileMenu(false);
    }
  }, [blogTextMain]);

  return (
    <>
      <div className="BlogPage">
        {isMobileMenuToggled === true ? (
          <div className="mobileMenuBackground">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                className="mobileMenu"
                style={{
                  marginLeft: 25,
                  marginRight: 25,
                  marginTop: 25,
                  width: "100%",
                  height: 45,
                }}
                onClick={() => setMobileMenu(!isMobileMenuToggled)}
              >
                Back
              </div>
              <div
                className="mobileMenu"
                style={{
                  marginLeft: "auto",
                  marginRight: 25,
                  marginTop: 25,
                  width: 94,
                  height: 45,
                }}
                onClick={() => navigate("/")}
              >
                Home
              </div>
            </div>
            <div style={{ marginRight: 25, marginLeft: 25, height: "100%" }}>
              <BlogMenuDesktop setBlogText={setBlogText} />
            </div>
          </div>
        ) : null}
        <div className="PageSection PageSectionLeft">
          <BlogMenuDesktop
            setBlogText={setBlogText}
            setIsLoading={setIsLoading}
          />
        </div>
        <div className="PageSection PageSectionMiddle">
          <div
            className="mobileMenu"
            onClick={() => setMobileMenu(!isMobileMenuToggled)}
          >
            Menu
          </div>
          <BlogTextBox
            blogText={
              isLoading
                ? "# Loading... \n The blog is loading..."
                : blogTextMain
            }
          />
        </div>
        <div className="PageSection PageSectionRight">
          <VerticalMenuList />
        </div>
      </div>
    </>
  );
}
