import React from "react";
import "./root.css";

import { MainMenu } from "../components/MainMenu";

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { VerticalMenuList } from "../components/VerticalMenuList";

export const currentTab = atom({
  key: "currentTab",
  default: "menu",
});

function App() {
  const [tab, setTab] = useRecoilState(currentTab);
  return (
    <div className="App">
      <div className="ContentBox">
        <MainMenu setTab={setTab} tab={tab}></MainMenu>
        <div className="InfoBox MenuWidth">
          <h2>imp.works / imp.studio</h2>
          <p>
            Hello, I'm diinki! Welcome to my website. This website is a
            collection of Projects that I'm involved with, blogs and writings,
            as well as some of the artworks/designs/music that I've created or
            &nbsp;
            <a
              target="_blank"
              href="https://open.spotify.com/artist/1B7CulxLR4rOjwI8ods1dy?si=zGNo2ZFyRkKyRne50MsArA"
            >
              published.
            </a>
          </p>
          <p>Feel free to take your time and look around, or view my links.</p>
        </div>
      </div>
    </div>
  );
}
export default App;
