import React from "react";
import { useNavigate } from "react-router-dom";
import "./MainMenu.css";

export function MainMenu(props: any) {
  const navigate = useNavigate();

  return (
    <div className="MainMenuBox MenuWidth">
      <div onClick={() => navigate("art")} className="MainMenuButton">
        Artwork
      </div>
      <div onClick={() => navigate("projects")} className="MainMenuButton">
        Projects
      </div>
      <div onClick={() => navigate("blogs")} className="MainMenuButton">
        Blog
      </div>
      <div onClick={() => navigate("links")} className="MainMenuButton">
        Links
      </div>
    </div>
  );
}
