import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import "./error-page.css";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div id="error-page" className="main">
      <h1>Unlucky.</h1>
      <p>You've ecnountered an error.</p>
      <h3 style={{ marginTop: 0 }}>
        {isRouteErrorResponse(error) ? error.status : "Unknown Error"}
      </h3>
    </div>
  );
}
