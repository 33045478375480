import { Link } from "react-router-dom";
import "./links.css";
import { useNavigate } from "react-router-dom";
import { MenuButton } from "../../components/interactables/MenuButton";

export function Links() {
  const navigate = useNavigate();

  const openLink = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <div className="linksBox">
      <div className="mainBox">
        <h1>MY LINKS (o_o)/</h1>
        <div className="row">
          <MenuButton
            title="Youtube"
            action={() => {
              openLink("https://youtube.com/@diinkodiinki");
            }}
            iconCharCode="&#xf167;"
          />
          <MenuButton
            title="Twitch"
            action={() => {
              openLink("https://twitch.com/diinkodiinki");
            }}
            iconCharCode="&#xf1e8;"
          />
          <MenuButton
            title="TikTok"
            action={() => {
              openLink("https://tiktok.com/@diinkidiinko");
            }}
            iconCharCode="&#xe07b;"
          />
        </div>
        <div className="row">
          <MenuButton
            title="Insta"
            action={() => {
              openLink("https://instagram.com/diinkodiinki");
            }}
            iconCharCode="&#xf16d;"
          />
          <MenuButton
            title="X"
            action={() => {
              openLink(
                "https://twitter.com/diinkodiinki/status/1755047664419213513"
              );
            }}
            iconCharCode="&#xe61b;"
          />
          <MenuButton
            title="Bluesky"
            action={() => {
              openLink("https://bsky.app/profile/diinki.imp.works");
            }}
            iconCharCode="&#xf17c;"
          />
        </div>
        <div className="row">
          <MenuButton
            title="Spotify"
            action={() => {
              openLink(
                "https://open.spotify.com/artist/1B7CulxLR4rOjwI8ods1dy"
              );
            }}
            iconCharCode="&#xf1bc;"
          />
          <MenuButton
            title="Bandcamp"
            action={() => {
              openLink("https://diinki.bandcamp.com");
            }}
            iconCharCode="&#xf2d5;"
          />
        </div>
        <div className="row">
          <MenuButton
            title="Discord"
            action={() => {
              openLink("https://discord.gg/nCBwjHzP6e");
            }}
            iconCharCode="&#xf392;"
          />
        </div>
        <h4 style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <a>Go Back</a>
        </h4>
      </div>
    </div>
  );
}
