import React from "react";
import "./MenuButton.css";

const inline = {
  noBorderSection: {
    borderStyle: "none",
    padding: "12px",
    alignItems: "center",
    height: "100%",
    overflow: "auto",
  },
};

type menuButtonProps = {
  title: string;
  iconCharCode: string;
  action: () => void;
};

export function MenuButton(props: menuButtonProps) {
  return (
    <div
      onClick={() => {
        props.action();
      }}
      className="MenuButtonContainer"
    >
      <div className="Title">{props.title}</div>
      <div className="Icon">{props.iconCharCode}</div>
    </div>
  );
}
