import React, { useEffect, useState } from "react";
import "./BlogMenuDesktop.css";

import { BlogButton } from "../interactables/BlogButton";
import { InputField } from "../interactables/InputField";

import { Blog } from "../../types/IBlog";

import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import { getAllBlogs, getBlogByUri } from "../../utils/BlogUtils";

export const currentFilterText = atom({
  key: "filterText",
  default: "",
});

export const currentBlogIndex = atom({
  key: "blogIndex",
  default: 0,
});
export const allBlogs = atom({
  key: "allBlogs",
  default: [] as Blog[],
});

const urlParam = atom({
  key: "urlParam",
  default: "",
});

let hasSearched = false;
export function BlogMenuDesktop(props: any) {
  const [filterText, setText] = useRecoilState(currentFilterText);
  const [blogIndex, setBlogIndex] = useRecoilState(currentBlogIndex);

  const setBlogs = useSetRecoilState(allBlogs);
  const blogs = useRecoilValue(allBlogs);

  const [urlParamText, setUrlParamText] = useRecoilState(urlParam);

  /*  
  useEffect(() => {
    const filterTextFunc = () => {
      setFilteredBlogs(
        blogs.filter((item) => {
          if (
            item.title.toLowerCase().includes(filterText.toLocaleLowerCase()) ||
            item.categories
              .join(" ")
              .toLocaleLowerCase()
              .includes(filterText.toLocaleLowerCase())
          ) {
            return item;
          }
          return null;
        })
      );
    };
    filterTextFunc();
  }, [blogs, filterText, setFilteredBlogs]);
  */
  if (hasSearched === false) {
    getAllBlogs()
      .then((res) => {
        setBlogs(res);

        const currentUrl = new URL(window.location.href);
        const blogNameParam = currentUrl.searchParams.get("blogName");

        if (blogNameParam != null) {
          for (let i = 0; i < res.length; i++) {
            if (
              res[i].title.toLowerCase().includes(blogNameParam.toLowerCase())
            ) {
              setBlogIndex(i);
            }
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    hasSearched = true;
  }

  useEffect(() => {
    if (blogs.length > 0) {
      getBlogByUri(blogs[blogIndex].markdownTextURI)
        .then((text) => {
          props.setBlogText(text);
          //props.setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [blogIndex, blogs]);

  let GetButtons = () => {
    return (
      <>
        {blogs.map((blog, index) => {
          if (
            blog.title.toLowerCase().includes(filterText.toLocaleLowerCase()) ||
            blog.categories
              .join(" ")
              .toLocaleLowerCase()
              .includes(filterText.toLocaleLowerCase())
          ) {
            return (
              <BlogButton
                setBlogIndex={setBlogIndex}
                setIsLoading={props.setIsLoading}
                blogIndex={index}
                currentBlogIndex={blogIndex}
                blogCategories={blog.categories}
                blogTitle={blog.title}
                blogDate={blog.date}
                key={blog.title}
              />
            );
          }

          /*          
          if (
            (filterText != "" &&
              blog.title
                .toLowerCase()
                .includes(filterText.toLocaleLowerCase())) ||
            blog.categories
              .join(" ")
              .toLocaleLowerCase()
              .includes(filterText.toLocaleLowerCase())
          ) {
            <BlogButton
              setBlogIndex={setBlogIndex}
              setIsLoading={props.setIsLoading}
              blogIndex={index}
              currentBlogIndex={blogIndex}
              blogCategories={blog.categories}
              blogTitle={blog.title}
              blogDate={blog.date}
              key={blog.title}
            />;
          } else {
            <BlogButton
              setBlogIndex={setBlogIndex}
              setIsLoading={props.setIsLoading}
              blogIndex={index}
              currentBlogIndex={blogIndex}
              blogCategories={blog.categories}
              blogTitle={blog.title}
              blogDate={blog.date}
              key={blog.title}
            />;
          }
          */
        })}
      </>
    );
  };

  return (
    <>
      <div className="BlogMenu">
        <div className="BlogsFilterSection">
          <InputField
            customStyle={{
              height: "40px",
              fontSize: "18px",
              textAlign: "center",
            }}
            placeHolder="Search by name or tag"
            setText={setText}
          />
        </div>
        <div className="BlogsSelectionSection">
          <GetButtons />
        </div>
      </div>
    </>
  );
}
