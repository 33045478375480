import React, { useEffect } from "react";
import "./VerticalMenuList.css";
import { useNavigate } from "react-router-dom";

export function VerticalMenuList() {
  const navigate = useNavigate();
  return (
    <>
      <div className="Menu">
        <div className="SelectionSection">
          <div onClick={() => navigate("/")} className="MenuButton">
            Home
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div
              onClick={() => navigate("/art")}
              className="MenuButton"
              style={{ width: "auto", flexGrow: 1, marginLeft: 0 }}
            >
              Artworks
            </div>
            <div
              onClick={() => navigate("/projects")}
              className="MenuButton"
              style={{ width: "auto", flexGrow: 1, marginRight: 0 }}
            >
              Projects
            </div>
          </div>
          <div className="seperator" />
          <p className="infoText">
            More menu options will be eventually be added, including bespoke
            audio tracks made for each blog.
          </p>
        </div>
      </div>
    </>
  );
}
