import react from 'react';
import './InputField.css';


export function InputField(props: any) {

    let onInput = (inputText: string) => {
        props.setText(inputText);
    }
    return(
        <>
        <input placeholder={props.placeHolder} style={props.customStyle} className='InputField' onInput={(event: React.ChangeEvent<HTMLInputElement>) => onInput(event.target.value)}/>
        </>
    )
}