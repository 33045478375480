import react from "react";
import ReactDOM from "react-dom";
import ReactMarkdown from "react-markdown";
import { createRoot } from "react-dom/client";
import { Blog } from "../../types/IBlog";

import gfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

export function BlogTextBox(props: any) {
  require("./BlogTextBox.css");

  return (
    <>
      <div className="BlogContainer">
        <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[gfm]}>
          {props.blogText}
        </ReactMarkdown>
      </div>
    </>
  );
}
