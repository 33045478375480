import React from "react";
import "./BlogButton.css";

const inline = {
  noBorderSection: {
    borderStyle: "none",
    padding: "12px",
    alignItems: "center",
    height: "100%",
    overflow: "auto",
  },
};

export function BlogButton(props: any) {
  let GetTags = () => {
    return (
      <>
        {(props.blogCategories as string[]).map((tag) => (
          <div key={tag} className="Tag">
            {" "}
            {tag}{" "}
          </div>
        ))}
      </>
    );
  };

  let SetBlog = () => {
    //props.setIsLoading(true);
    props.setBlogIndex(props.blogIndex);
  };

  return (
    <div
      onClick={SetBlog}
      className="BlogButtonContainer"
      style={
        props.blogIndex === props.currentBlogIndex
          ? { borderColor: "var(--accentColor)" }
          : {}
      }
    >
      <div className="BlogButtonSection">
        <div className="Title">
          {props.blogTitle}
          <div className="Date">{props.blogDate}</div>
        </div>
      </div>
      <div className="BlogButtonSection" style={inline.noBorderSection}>
        <GetTags />
      </div>
    </div>
  );
}
