import { rejects } from "assert";
import axios from "axios";
import { resolve } from "path";
import { Blog } from "../types/IBlog";


export async function getAllBlogs(): Promise<Blog[]> {
    return new Promise(function(resolve, reject){
        axios.get('https://raw.githubusercontent.com/diinki/my_writings/main/blogData.json').then((response) => {
            let blogs: Blog[];
            blogs = response.data.blogs as Blog[];
            blogs.sort((a, b) => {
              return b.date.localeCompare(a.date);
            });
           resolve(blogs);
        }).catch((error) => {
            reject(error);
        });
    });
}

export async function getBlogByUri(uri: string): Promise<string> {
    return new Promise(function(resolve, reject){
        axios.get(uri).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error)
        });
    });
}