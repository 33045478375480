import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";

import { RecoilRoot } from "recoil";
import ErrorPage from "./pages/error-page";
import { BlogPage } from "./routes/blog/blog";
import { Artworks } from "./routes/artworks/artworks";
import { Projects } from "./routes/projects/projects";
import { Links } from "./routes/links/links";

let host = window.location.host;
let protocol = window.location.protocol;
let parts = host.split(".");
let subdomain = "";

/*
if (parts.length >= 3) {
  subdomain = parts[0];
  parts.splice(0, 1);

  window.location.assign(protocol + "//" + parts.join(".") + "/" + subdomain);
}
*/

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "blogs/",
    element: <BlogPage />,
  },
  {
    path: "art/",
    element: <Artworks />,
  },
  {
    path: "projects/",
    element: <Projects />,
  },
  {
    path: "links/",
    element: <Links />,
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <RecoilRoot>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </RecoilRoot>
);

//<App />
