import { Link } from "react-router-dom";
import "./artworks.css";
import { useNavigate } from "react-router-dom";

export function Artworks() {
  const navigate = useNavigate();
  return (
    <>
      <div className="box">
        <div className="mainBox">
          <h1>Work-in-Progress</h1>
          <h4 style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
            Go Back
          </h4>
        </div>
      </div>
    </>
  );
}
